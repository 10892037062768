import React from 'react';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import Slider from 'react-slick';
import Scrollbar from 'react-scrollbars-custom';
import Layout from '../components/Layout';
import Subheader from '../components/Subheader';
import Hashtags from '../components/Hashtags';
import { IconVideo, IconText, IconAudio, IconVeranstaltung } from '../components/Icons';

import { getDateAndTime } from '../utils/common';

const settings = {
  autoplay: true,
  arrows: false,
  dots: true,
  infinite: true,
  speed: 1250,
  slidesToShow: 1,
  slidesToScroll: 1,
  swipeToSlide: true,
  appendDots: dots => (
    <div>
      <ul> {dots} </ul>
    </div>
  )
};

const IndexPage = ({ data }) => {
  const mainPageCards =
    data.allDatoCmsMainPage.edges.length > 0 && data.allDatoCmsMainPage.edges[0].node;

  const Veranstaltungen = data.allDatoCmsVeranstaltungen
    ? [...data.allDatoCmsVeranstaltungen.edges]
    : [];
  const VeranstaltungenReversed = Veranstaltungen.reverse();

  const Schulung = data.allDatoCmsSchulung ? [...data.allDatoCmsSchulung.edges] : [];
  const SchulungReversed = Schulung.reverse();

  const getClosestDate = dates => {
    const now = new Date();
    const heightOfEventRow = 112; //px
    let closest = Infinity;
    let position = 0;

    dates.forEach(({ node }, index) => {
      const date = new Date(node.beginn);

      if (date >= now && (date < new Date(closest) || date < closest)) {
        closest = node.beginn;
        position = index;
      }
    });

    if (position === 0) {
      position = dates.length;
    }

    return position * heightOfEventRow;
  };

  return (
    <Layout
      title={mainPageCards.seo && mainPageCards.seo.title}
      description={mainPageCards.seo && mainPageCards.seo.description}
    >
      <div className="main-page">
        <Subheader />

        <div className="container">
          {/* <div className="row mt-0">
            <div className="col">
              <Link to="/about" className="bar">
                Die politische Akademie von <b> JETZT </b> Liste Pilz
              </Link>
            </div>
          </div>*/}

          <div className="row">
            <div className="col-lg-12 col-md-12">
              <Slider {...settings}>
                {data.allDatoCmsThemenschwerpunkte.edges.map(({ node }) => (
                  <div className="post post--large" key={node.id}>
                    <Link to={`/themenschwerpunkte/${node.link}`}>
                      <Img
                        fluid={node.image.fluid}
                        alt={node.beitragsname}
                        style={{ height: '100%' }}
                      />
                      <div className="post__label">
                        <div className="post__label__wrapper">
                          <h2 className="title title--white">{node.beitragsname}</h2>
                          <h3>Themenschwerpunkt: #{node.hashtag}</h3>
                        </div>
                      </div>
                    </Link>
                  </div>
                ))}
              </Slider>
            </div>
          </div>

          {/* AKTUELL */}
          <div style={{ backgroundColor: '#ccc' }}>
            <p
              style={{
                fontWeight: 600,
                fontSize: 24,
                marginBottom: 5,
                paddingLeft: 20,
                paddingTop: 5
              }}
            >
              AKTUELL
            </p>
            <div className="row">
              {data.allDatoCmsBeitragBvogOfakt.edges.map(({ node }) => (
                <div className="col-md-4" key={node.id}>
                  <Link to={`/beitraege/${node.link}`}>
                    <div className="post post--small">
                      <Img
                        fluid={node.bvogMainPicture.fluid}
                        alt={node.beitragsname}
                        style={{ height: '100%' }}
                      />
                      <div className="post__label">
                        <div className="post__label__wrapper">
                          <h3>{node.bvogFrontTitel}</h3>
                        </div>
                      </div>
                      <div className="post__top__label">
                        <div className="content-hashtags">
                          {node.themenschwerpunkt.map((tag, index) => (
                            <div key={index}>#{tag.link}</div>
                          ))}
                        </div>

                        <div className="content-icons">
                          {node.bvogContentModules.some(
                            content => content.__typename === 'DatoCmsBvogModText'
                          ) && <IconText />}
                          {node.bvogVeranstaltungen.length > 0 && <IconVeranstaltung />}
                          {node.bvogContentModules.some(
                            content => content.__typename === 'DatoCmsBvogModVideo'
                          ) && <IconVideo />}
                          {node.bvogContentModules.some(
                            content => content.__typename === 'DatoCmsBvogModPodcast'
                          ) && <IconAudio />}
                        </div>
                      </div>
                    </div>
                  </Link>
                </div>
              ))}
            </div>
          </div>

          <div style={{ backgroundColor: '#ccc', marginBottom: '30px' }}>
            <div className="row">
              <div className="col-md-12">
                {mainPageCards.hugeCard.map(card => (
                  <Link to={`/${card.link}`} key={card.id}>
                    <div className="post post--large" style={{ maxHeight: 300 }}>
                      <Img fluid={card.image.fluid} alt={card.heading} style={{ height: '100%' }} />
                      <div className="post__top__label">
                        <div className="content-hashtags">
                          {card.hashtag && <Hashtags hashtag={card.hashtag} />}
                        </div>
                      </div>
                      <div className="post__label">
                        <div className="post__label__wrapper">
                          <h2 className="title title--white">{card.heading}</h2>
                          {card.subheading && <h3>{card.subheading}</h3>}
                        </div>
                      </div>
                    </div>
                  </Link>
                ))}

                {/*
                <a href="/beitraege/bvog-fnl">
                  <div class="post post--large" style="max-height:300px">
                    <div class=" gatsby-image-wrapper" style="position:relative;overflow:hidden;height:100%">
                      <div aria-hidden="true" style="width:100%;padding-bottom:56.25%"></div>
                        <img sizes="(max-width: 1100px) 100vw, 1100px" src="https://www.datocms-assets.com/11130/1640685555-2-bvog.jpg?auto=compress%2Cformat" alt="Das ganze Interview mit Daniela Kraus und Fritz Hausjell" loading="lazy" style="position: absolute; top: 0px; left: 0px; width: 100%; height: 100%; object-fit: cover; object-position: center center; opacity: 1; transition: opacity 500ms ease 0s;" />
                    </div>
                    <div class="post__top__label">
                      <div class="content-hashtags">
                        <span>#offenegesellschaft</span>
                        <span>#medienunabhaengigkeit</span>
                      </div>
                    </div>
                    <div class="post__label">
                      <div class="post__label__wrapper">
                        <h2 class="title title--white">Das ganze Interview mit Daniela Kraus und Fritz Hausjell</h2>
                        <h3>Eine offene Gesellschaft beginnt mit  unabhängigen Medien!</h3>
                      </div>
                    </div>
                  </div>
                </a>
                */}

              </div>
            </div>
          </div>

          <div className="row">
            {mainPageCards.smallCards.map(card => (
              <div className="col-md-4" key={card.id}>
                <Link to={`/${card.link}`}>
                  <div className="post post--small">
                    <Img fluid={card.image.fluid} alt={card.heading} style={{ height: '100%' }} />
                    <div className="post__label">
                      <div className="post__label__wrapper">
                        <h2 className="title title--white">{card.heading}</h2>
                        {card.subheading && <h3>{card.subheading}</h3>}
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}
          </div>

          <div className='row'>
          {mainPageCards.largeCards.map(card => (
              <div className="col-lg-12 col-md-12" key={card.id}>
                <Link to={`/${card.link}`}>
                  <div className="post post--medium">
                    <Img fluid={card.image.fluid} alt={card.heading} style={{ height: '100%' }} />
                    <div className="post__label">
                      <div className="post__label__wrapper">
                        <h2 className="title title--white">{card.heading}</h2>
                        <h3>{card.subheading}</h3>
                      </div>
                    </div>
                  </div>
                </Link>
              </div>
            ))}

          </div>

          <div className="row wrapper__card_and_scrollbar">   

            <div className="col-lg-6 col-md-12 mb-5 mb-lg-0">
              <Link to="/veranstaltungen" className="article-list__title">
                Vergangene Veranstaltungen
              </Link>
              <Scrollbar
                thumbYProps={{ className: 'scrollbarCustom__thumb' }}
                trackYProps={{ className: 'scrollbarCustom__track' }}
                noScrollX
                scrollTop={getClosestDate(VeranstaltungenReversed)}
                style={{ height: 325 }}
              >
                <div className="article-list">
                  {VeranstaltungenReversed.map(({ node }) => (
                    <Link to={`/veranstaltungen/${node.link}`} key={node.id}>
                      <article>
                        <div className="content">
                          <div>
                            <span className="date">{getDateAndTime(node.beginn)}</span>
                            <span className="place">{node.ort}</span>
                          </div>
                          <div>
                            <span className="name">{node.series}</span>
                          </div>
                          <div>
                            <span className="name">{node.frontTitle}</span>
                          </div>
                          <div>
                            <span className="name">{node.art}</span>
                          </div>
                        </div>
                        <div className="content-overlay" />
                        <div style={{ minWidth: 97 }}>
                          {node.image && (
                            <Img
                              fluid={node.image.fluid}
                              alt={node.frontTitle}
                              style={{ height: '100%', minWidth: 97 }}
                            />
                          )}
                        </div>
                      </article>
                    </Link>
                  ))}
                </div>
              </Scrollbar>
            </div>

            <div className="col-lg-6 col-md-12">
              <Link to="/schulungen" className="article-list__title">
                Vergangene Schulungen
              </Link>
              <Scrollbar
                thumbYProps={{ className: 'scrollbarCustom__thumb' }}
                trackYProps={{ className: 'scrollbarCustom__track' }}
                scrollTop={getClosestDate(SchulungReversed)}
                style={{ height: 325 }}
              >
                <div className="article-list">
                  {SchulungReversed.map(({ node }) => (
                    <Link to={`/veranstaltungen/${node.link}`} key={node.id}>
                      <article>
                        <div className="content">
                          <div>
                            <span className="date">{getDateAndTime(node.beginn)}</span>
                            <span className="place">{node.ort}</span>
                          </div>
                          <div>
                            <span className="name">{node.series}</span>
                          </div>
                          <div>
                            <span className="name">{node.frontTitle}</span>
                          </div>
                          <div>
                            <span className="name">{node.art}</span>
                          </div>
                        </div>
                        <div className="content-overlay" />
                        <div style={{ minWidth: 97 }}>
                          {node.image && (
                            <Img
                              fluid={node.image.fluid}
                              alt={node.frontTitle}
                              style={{ height: '100%', minWidth: 97 }}
                            />
                          )}
                        </div>
                      </article>
                    </Link>
                  ))}
                </div>
              </Scrollbar>
            </div>
          </div>

          <div className="row">
            {mainPageCards.mediumCards.map(card => {
              if (card.link.includes('http')) {
                return (
                  <div className="col-md-6" key={card.id}>
                    <a href={card.link} target="_blank" rel="noopener noreferrer">
                      <div className="post post--small ofakt">
                        <img src={card.image.url} alt={card.heading} />
                        <div className="post__label">
                          <div className="post__label__wrapper">
                            <h2 className="title title--white">{card.heading}</h2>
                            {card.subheading && <h3>{card.subheading}</h3>}
                          </div>
                        </div>
                      </div>
                    </a>
                  </div>
                );
              } else {
                return (
                  <div className="col-md-6" key={card.id}>
                    <Link to={`/${card.link}`}>
                      <div className="post post--small ofakt">
                        <Img
                          fluid={card.image.fluid}
                          alt={card.heading}
                          style={{ height: '100%' }}
                        />
                        <div className="post__label">
                          <div className="post__label__wrapper">
                            <h2 className="title title--white">{card.heading}</h2>
                            {card.subheading && <h3>{card.subheading}</h3>}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                );
              }
            })}
          </div>
        </div>
      </div>
    </Layout>
  );
};

export default IndexPage;

export const query = graphql`
  query IndexPageQuery {
    allDatoCmsMainPage {
      edges {
        node {
          seo {
            title
            description
          }
          smallCards {
            ... on DatoCmsCard {
              id
              heading
              subheading
              hashtag
              image {
                fluid(maxWidth: 350, imgixParams: { auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              link
            }
          }
          mediumCards {
            ... on DatoCmsCard {
              id
              heading
              subheading
              hashtag
              image {
                url
                fluid(maxWidth: 540, imgixParams: { auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              link
            }
          }
          largeCards {
            ... on DatoCmsCard {
              id
              heading
              subheading
              hashtag
              image {
                url
                fluid(maxWidth: 730, imgixParams: { auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              link
            }
          }
          hugeCard {
            ... on DatoCmsCard {
              id
              heading
              subheading
              hashtag
              image {
                fluid(maxWidth: 1100, imgixParams: { auto: "compress" }) {
                  ...GatsbyDatoCmsFluid
                }
              }
              link
            }
          }
        }
      }
    }

    allDatoCmsBeitragBvogOfakt(
      filter: { bvog: { eq: true } }
      limit: 9
      sort: { fields: [position], order: ASC }
    ) {
      edges {
        node {
          ...DatoBeitragFields
        }
      }
    }

    allDatoCmsVeranstaltungen(
      sort: { fields: [beginn], order: DESC }
      filter: { art: { ne: "Schulung" } }
    ) {
      edges {
        node {
          id
          frontTitle
          series
          art
          beginn
          ort
          image {
            fluid(maxWidth: 97, maxHeight: 97, imgixParams: { auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          link
        }
      }
    }

    allDatoCmsSchulung: allDatoCmsVeranstaltungen(
      limit: 25
      sort: { fields: [beginn], order: DESC }
      filter: { art: { eq: "Schulung" } }
    ) {
      edges {
        node {
          id
          frontTitle
          series
          art
          beginn
          ort
          image {
            fluid(maxWidth: 97, maxHeight: 97, imgixParams: { auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          link
          arbeitsweise {
            link
          }
        }
      }
    }

    allDatoCmsThemenschwerpunkte(limit: 5) {
      edges {
        node {
          id
          hashtag
          beitragsname
          image {
            fluid(maxWidth: 730, imgixParams: { auto: "compress" }) {
              ...GatsbyDatoCmsFluid
            }
          }
          link
        }
      }
    }

    allDatoCmsOsterreichbilanzen {
      edges {
        node {
          hashtag
        }
      }
    }
  }
`;
