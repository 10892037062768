export const getCorrectPath = (page, tags, article) => {
  switch (page.toLowerCase()) {
    case 'expertise':
      return `/expertise/${article}`;

    case 'reportage':
      return `/reportage/${article}`;

    case 'osterreichbilanz':
      return `/osterreich-bilanz/${tags.oesterreichbilanz[0].link}/${article}`;

    case 'themenschwerpunkt':
      return `/themenschwerpunkte/${tags.themenschwerpunkt[0].link}/${article}`;

    default:
      console.log('Route not find');
  }
};

export const concateneteHashtags = (
  hashtags,
  themenschwerpunkt,
  oesterreichbilanz,
  arbeitsweise
) => {
  const allHashtags = [];

  hashtags.split(',').forEach(tag => {
    allHashtags.push(tag.trim());
  });
  themenschwerpunkt.forEach(tag => {
    allHashtags.push(tag.link);
  });
  oesterreichbilanz.forEach(tag => {
    allHashtags.push(tag.link);
  });
  arbeitsweise &&
    arbeitsweise.forEach(tag => {
      allHashtags.push(tag.link);
    });

  return allHashtags.join(', ');
};

export const mixedListOfArticlesAndEvents = (articles, events) => {
  const AllPosts = [];
  const HighLength = articles.length > events.length ? articles.length : events.length;

  for (let index = 0; index < HighLength; index++) {
    if (articles[index]) {
      AllPosts.push(articles[index]);
    }

    if (events[index]) {
      AllPosts.push(events[index]);
    }
  }

  return AllPosts;
};

export const getDateAndTime = data => {
  return new Intl.DateTimeFormat('de-at', {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    timeZone: 'Europe/Vienna'
  }).format(Date.parse(data));
};
